.date-header{
    color:#E81E1E;
    font-weight:bold;
    font-size: 0.9rem;
    margin: 5px;
}

.location-container{
    background:#F7F0E4;
    padding:10px;
    border-radius:6px;
    display:flex;
    flex-direction:column;
}

.item-container{
    display:flex;
    flex-direction:row;
    align-items: center;
}

.item{
    color:#000;
    display:flex;
    flex-direction:column;
    flex:1;
    margin:2px;
    font-size: 0.7rem;
}

.item-container a{
    font-size: 0.7rem;
    color:#000;
    text-decoration: underline;
}
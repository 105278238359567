@font-face {
  font-family: 'Mecherle Sans';
  src: url('./fonts/MecherleSans-Regular.otf');
}
@font-face {
  font-family: 'Mecherle Sans';
  font-weight: 700;
  src: url('./fonts/MecherleSans-Bold.otf');
}
@font-face {
  font-family: 'Mecherle Sans';
  font-weight: 600;
  src: url('./fonts/MecherleSans-SemiBold.otf');
}


body {
  background: #ffffff;
  margin:0;
  font-family: 'Mecherle Sans', sans-serif;
  color:#fff;
  background: #E81E1E;
}

#root{
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.center {
  position: relative;
  width: 100%;
  background: #E81E1E;
}
.content{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 720px;
  /* height: calc(100vh - 65px);
  height: calc(var(--vh, 1vh) * 100); */
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  justify-content:space-evenly;
  position: relative;
  overflow: hidden;
  padding-left: 20px;
  padding-top: 10px;
  box-sizing: border-box;
}

.footer{
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  text-align: center;
  width: 100%;
  font-family: interFont, Helvetica, Verdana, "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #A19AA9;
  margin-top: 7px;
}
.dynamicSpacer{
  margin-top: 96px; width: 100%;
}


.loader.fade-out{
  animation: fade-out 1.5s linear;
}

.header{
  font-weight: bold;
  font-size: 1.5rem;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0.5vh;
  line-height: 1.5rem
}

.desc-text{
  line-height: inherit;
  font-size: 1rem;
  text-align: left;
  margin-top: 0;
  margin: 10px 0;
}

.cta{
  /* width: 40vw; */
  /* height: 5vh; */
  padding:0.4rem;
  max-height: 50px;
  max-width: 200px;
  background: #fff;
  color: #e81e1e;
  border: none;
  border-radius: 23px;
  font-size: 1.2rem;
  font-weight: 700;
  cursor: pointer;
  margin: 0.2rem 0;
}

.logo{
  width: 40%;
}

.footballs{
  object-fit: contain;
  display: none;
}
.header{
  margin: 0.5vh 0;
  font-size: 1.8rem;
  line-height: 2.0rem;
}
.header2{
  margin: 10px 0;
  font-size: 2rem;
  line-height: 2.0rem;
  font-weight: 600;
}

.desc-text2{
  margin: 10px 10px 10px 0;
  line-height: inherit;
  font-size: 1rem;
}

.container2{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
  max-width: 800px;
}

.video-container{
  max-width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 23px;
  border-radius: 28px;
  box-sizing: border-box;
  align-self: center;
}

.video-container span{
  color:#E81E1E;
  margin: 20px 0;
  font-weight: 700;
  font-size: 1.3rem;
  text-align: center;
}

.image-container{
  display: flex;
  flex-direction: column;
}
@media screen and (min-height:641px){
  .content{
    padding-left: 20px;
  }
  .text-container{
    width: 80%
  }
  .footballs{
    display: block;
  }
}

@media screen and (min-width: 768px){
  .content{
    padding:0;
  }
}
@keyframes fade-out{
  0%{opacity: 1;}
  75%{opacity:1;}
  100%{opacity: 0;}
}
/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/

html, body{
    height: 100%;
    overflow: auto;
}


.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
    background-color: #F7F0E4;
    color: #e81e1e;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    box-sizing:border-box;
}

.accordion__button:hover {
    background-color: #ddd;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    background: #fff;
    padding: 20px;
    animation: fadein 0.35s ease-in;
}

.accordion__item{
    color:#000;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
